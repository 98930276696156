import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../../locationProvider/locationHOC';
import Table from '../../general/Table';
import Tile from '../../general/Tile';
import TileAttribute from '../../general/TileAttribute';
import Link from '../../navigation/Link';
import DateTime from '../../general/DateTime';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import {RIGHTS} from '../../../constants/Rights';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import {navigateToParametrized, getQueryParam} from '../../../lib/url';
import {numberOrNull} from '../../../lib/number';
import {getBackgroundStyle} from '../../../lib/project';
import {formatProductName, formatQuantity, formatUnitPrice} from '../../../lib/formatting';
import {Order, OrderDetailLine, Trade} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import { ORDER_LINE_STATE } from '../../../constants/orderStates';
import OrderAvailableAt from '../orderDetails/OrderAvailableAt';
import OrderRequestedAt from '../orderDetails/OrderRequestedAt';
import orderItemHOC from '../orderDetails/orderItemHOC';

/**
 * @dusan
 */

class OrderLinesTable extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        reload: PropTypes.func.isRequired,
        orderLines: PropTypes.arrayOf(OrderDetailLine.isRequired).isRequired,
    };

    setOrdering = (newOrder) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ORDER_LINES_ORDER_BY]: newOrder,
            [QUERY_PARAMS.ORDER_LINES_SCROLL]: 0,
        });
    };

    setLimit = (newLimit) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ORDER_LINES_LIMIT]: newLimit,
        });
    };

    render() {
        const {location, reload, orderLines} = this.props;
        const orderBy = getQueryParam(location, QUERY_PARAMS.ORDER_LINES_ORDER_BY);
        const currentLimit = numberOrNull(getQueryParam(location, QUERY_PARAMS.ORDER_LINES_LIMIT));

        return <Table
            className="order-lines-table"
            BodyRow={OrderLinesTableRowWrapped}
            BodyTile={OrderLinesTileWrapped}
            data={orderLines}
            limit={currentLimit}
            pageSize={!Number.isNaN(Number(process.env.DEFAULT_TABLE_PAGE_SIZE)) ? Number(process.env.DEFAULT_TABLE_PAGE_SIZE) : 20}
            changeLimit={this.setLimit}
            orderBy={orderBy}
            changeOrder={this.setOrdering}
            reload={reload}
            noTBody={false}
            colDefs={[
                {
                    headerCell: <Trans>Č. objednávky</Trans>,
                    orderCol: 'order_reference',
                    class: 'order-lines-col-ref'
                },
                {
                    headerCell: <Trans>Zákazník</Trans>,
                    orderCol: 'customer',
                    class: 'order-lines-col-customer',
                    rightsFrom: RIGHTS.WORKER
                },
                {
                    headerCell: <Trans>Produkt</Trans>,
                    orderCol: 'designation',
                    class: 'order-lines-col-designation'
                },
                {
                    headerCell: <Trans>Množstvo</Trans>,
                    orderCol: 'package_quantity',
                    class: 'order-lines-col-quantity',
                    descFirst: true
                },
                {
                    headerCell: <Trans>Jedn. cena</Trans>,
                    orderCol: 'package_price',
                    class: 'order-lines-col-price',
                    descFirst: true
                },
                {
                    headerCell: <Trans>Stav</Trans>,
                    orderCol: 'id_status',
                    class: 'order-lines-col-status'
                },
                {
                    headerCell: <Trans>Dátum a čas odoslania</Trans>,
                    orderCol: 'sent_at_or_now',
                    class: 'order-lines-col-sent-at',
                    descFirst: true,
                },
                {
                    headerCell: <Trans>Dátum potreby</Trans>,
                    orderCol: 'requested_at',
                    descFirst: true,
                    class: 'order-lines-col-requested-at',
                    rightsFrom: RIGHTS.WORKER
                },
                {
                    headerCell: <Trans>Dodací termín</Trans>,
                    orderCol: 'available_at',
                    descFirst: true,
                    class: 'order-lines-col-available-at',
                },
                {
                    headerCell: <Trans>Poznámka</Trans>,
                    class: 'order-lines-col-notices'
                },
                
            ]}
        />;
    }
}

export default locationHOC(OrderLinesTable);

class OrderLinesTableRow extends React.PureComponent {
    static propTypes = {
        data: OrderDetailLine.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onAvailableAtChange: PropTypes.func.isRequired,
    };

    render() {
        const {data, onRequestedAtChange, onAvailableAtChange} = this.props;
        return <tr className={getBackgroundStyle(data.trade, {isAutoSupply: data.auto_supply, isService: data.is_service, isNotCovered: data.id_status == ORDER_LINE_STATE.NOT_COVERED})}>
            <td className="overflow-wrap-break-word">
                <Link
                    className="text-dark"
                    to={ROUTES.ORDER_DETAILS}
                    queryParams={{[QUERY_PARAMS.ID_ORDER]: data.id_order}}
                    title={<Trans>Detail objednávky</Trans>}
                >
                    {data.order_reference}
                </Link>
                { data.order_customer_reference != null ? 
                    <div className="font-italic">
                        {data.order_customer_reference}
                    </div> : null
                }
            </td>
            <RightsWrapper from={RIGHTS.WORKER}>
                <td>{data.customer}</td>
            </RightsWrapper>
            <td>
                <Link
                    className="text-dark"
                    to={ROUTES.PRODUCT_DETAILS}
                    queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id_product}}
                    title={<Trans>Zobraziť detail produktu</Trans>}
                >
                    {formatProductName(data.designation, data.manufacturer)}
                </Link>
            </td>
            <td className="text-right">
                <div>
                    {formatQuantity(data.package_quantity, data.package_type)}
                </div>
                { Number(data.opened_quantity) > 0 ?
                    <div className="table-subdata">
                        {' ('}
                        <Trans>nedodané:</Trans>
                        {' '}
                        {formatQuantity(data.opened_quantity, data.quantity_units)}
                        {')'}
                    </div> :
                    null
                }
            </td>
            <td className="text-right">{formatUnitPrice(data.package_price, data.package_type, data.id_currency)}</td>
            <td>{data.status}</td>
            <td className="text-right">
                <DateTime timeString={data.sent_at}/>
            </td>
            <RightsWrapper from={RIGHTS.WORKER}>
                <td className="text-right">
                    <OrderRequestedAt
                        orderDetailsLine={data}
                        onChange={onRequestedAtChange}
                    />
                </td>
            </RightsWrapper>
            <td className="text-right">
                <OrderAvailableAt
                    orderDetailsLine={data}
                    onChange={onAvailableAtChange}
                />
            </td>
            <td>
                <div className="text-overflow-ellipsis">{data.customer_notice}</div>
                <RightsWrapper from={RIGHTS.WORKER}>
                    {data.dealer_notice != null ? <div className="font-italic text-overflow-ellipsis">{data.dealer_notice}</div> : null}
                </RightsWrapper>
            </td>
        </tr>;
    }
}

const OrderLinesTableRowWrapped = orderItemHOC(OrderLinesTableRow);

class OrderLinesTile extends React.PureComponent {
    static propTypes = {
        data: Order.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onAvailableAtChange: PropTypes.func.isRequired,
    };

    render() {
        const {data, onRequestedAtChange, onAvailableAtChange} = this.props;
        return <Tile className={"p-2 " + getBackgroundStyle(data.trade, {isAutoSupply: data.auto_supply, isService: data.is_service, isNotCovered: data.id_status == ORDER_LINE_STATE.NOT_COVERED})}>
            <div className="flex-row-dynamic-static">
                <div className="align-self-center">
                    <Link
                        className="text-dark"
                        to={ROUTES.PRODUCT_DETAILS}
                        queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id_product}}
                    >
                        <h4 className="px-2 mt-1">{formatProductName(data.designation, data.manufacturer)}</h4>
                    </Link>
                    <TileAttribute
                        title={<Trans>Objednávka:</Trans>}
                        value={
                            <Link
                                className="text-dark"
                                to={ROUTES.ORDER_DETAILS}
                                queryParams={{[QUERY_PARAMS.ID_ORDER]: data.id_order}}
                                title={<Trans>Zobraziť detail objednávky</Trans>}
                            >
                                {data.order_reference}
                            </Link>
                        }
                    />
                    <TileAttribute
                        title={
                            <React.Fragment>
                                <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                                    <Trans>Vaše č. objednávky</Trans>
                                </RightsWrapper>
                                <RightsWrapper from={RIGHTS.MARKETING}>
                                    <Trans>Č. obj. organizácie</Trans>
                                </RightsWrapper>
                            </React.Fragment>
                        }
                        value={data.customer_reference}
                    />
                </div>
            </div>
            <TileAttribute
                title={<Trans>Množstvo</Trans>}
                value={
                    <div>
                        <span className="text-nowrap">
                            {formatQuantity(data.package_quantity, data.package_type)}
                        </span>
                        { Number(data.opened_quantity) > 0 ?
                            <span className="font-weight-bold">
                                {' ('}
                                <Trans>nedodané:</Trans>
                                {' '}
                                {formatQuantity(data.opened_quantity, data.quantity_units)}
                                {')'}
                            </span> :
                            null
                        }
                    </div>
                }
            />
            <TileAttribute
                title={<Trans>Jedn. cena</Trans>}
                value={formatUnitPrice(data.package_price, data.package_type, data.id_currency)}
            />
            <TileAttribute
                title={<Trans>Odoslané</Trans>}
                value={<DateTime timeString={data.sent_at}/>}
            />
            <TileAttribute
                title={<Trans>Stav</Trans>}
                value={data.status}
            />
            <RightsWrapper from={RIGHTS.WORKER}>
                <TileAttribute
                    title={<Trans>Dátum potreby</Trans>}
                    value={
                        <OrderRequestedAt
                            orderDetailsLine={data}
                            onChange={onRequestedAtChange}
                        />
                    }
                />
            </RightsWrapper>
            <TileAttribute
                title={<Trans>Dodací termín</Trans>}
                value={
                    <OrderAvailableAt
                        orderDetailsLine={data}
                        onChange={onAvailableAtChange}
                    />
                }
            />
            <TileAttribute
                title={<Trans>Poznámka</Trans>}
                value={<div className="text-overflow-ellipsis">{data.customer_notice}</div>}
            />
            <RightsWrapper from={RIGHTS.MARKETING}>
                <TileAttribute
                    title={<Trans>Interná poznámka</Trans>}
                    value={<div className="text-overflow-ellipsis">{data.dealer_notice}</div>}
                />
            </RightsWrapper>
        </Tile>;
    }
}

const OrderLinesTileWrapped = orderItemHOC(OrderLinesTile);